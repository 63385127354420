import React from "react";
import AccountPageNavigation from "../components/account/AccountPageNavigation";
import Breadcrumbs from "../components/common/breadcrumbs/Breadcrumbs";
import Layout from "../layout/Layout";
import RepresentativeNavigation from "../components/account/RepresentativeNavigation";
import useGetRepresentativesDetails from "../hooks/representatives-details/useGetRepresentativesDetails";
import { staticPage } from "../../config/staticPage";

const MessagesPage = ({ location }) => {
  const { details: representative } = useGetRepresentativesDetails();

  return (
    <>
      <div className="page-wrapper account-page-wrapper">
        <div className="container">
          <Breadcrumbs location={location} />
        </div>

        <div className="container">
          <div className="account-content">
            <div className="row flex flex-wrap page-layout">
              <div className="col page-sidebar">
                <div className="page-sidebar-inner">
                  <AccountPageNavigation path={"/messages"} />
                  {representative && (
                    <RepresentativeNavigation representative={representative} />
                  )}
                </div>
              </div>

              <div className="col page-content">
                <div className="page-content-inner">
                  <div className="page-heading-section">
                    <h1 className="page-title">messages</h1>
                  </div>

                  {/* <MessageListing
                  page={1}
                  limit={20}
                  hasMoreData={false}
                  meassages={data}
                />
                <div className="hide">
                  <h4 className="page-title title-small">send messages</h4>
                  <MessageSendForm />
                </div> */}

                  <h6>
                    Have a question or comment about your order or need to send
                    a message to support?
                  </h6>
                  <p>
                    Reach out to us via our contact page and this will create a
                    ticket so we can make sure your request or concern is
                    handled in a timely manner.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export const Head = () => {
  let login_nav = staticPage;
  let loginDetails = null;
  for (const obj of login_nav) {
    if (obj.name === "messages") {
      loginDetails = obj;
      break; // Exit the loop once the desired object is found.
    }
  }
  return (
    <>
      <title>{loginDetails?.title}</title>
      <meta name="description" content={loginDetails?.description} />
    </>
  );
};

MessagesPage.Layout = Layout;
export default MessagesPage;
